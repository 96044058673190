import React from "react"
import { graphql, Link } from "gatsby"
import styled from "styled-components"
import { Container } from "../components/lib/Container"
import IndiceElement from "./../components/IndiceElement"
import { ArrowLeft } from "react-feather"
import { colorThemes } from "./../styles/colorThemes"
import PlanList from "./../components/PlanList"
import CallToAction from "../components/sections/CallToActionSection"
import ContentSection from "./../components/lib/ContentSection"
import { Button, tokens } from "@atrilflamenco/react-components"
import Footer from "../components/lib/Footer"
import iconSet from "../styles/iconmoon.json"
import IcomoonReact from "icomoon-react"
import { getIcon } from "../utils/getIcon"
import { getColor } from "../utils/getColor"

const Header = styled.div`
  position: relative;
  // background-color: rgb(${props => colorThemes[props.theme - 1][1]});
  &::before {
    content: '';
    position: fixed;
    top: -50%;
    left: -25%;
    width: 150vw;
    height: 80vh;
    transform: rotate(-10deg);
    pointer-events: none;
    background-image: linear-gradient(rgb(${props =>
      colorThemes[props.theme - 1][1]}), transparent);
    z-index: -1
  }
`

const TopHeader = styled.div`
  // background-color: rgba(${props => colorThemes[props.theme - 1][2]}, 1);
  // color: white;
`

export default function Plan({ data }) {
  const { plan } = data.atril
  const { tableOfContents } = data.atril.plan
  let parent = null
  return (
    <>
      <Container className="!pt-[4rem] mb-16">
        <TopHeader
          theme={plan.categoriaId}
          className="py-4 mb-6 z-10 backdrop-blur-lg"
        >
          <div className="max-w-screen-xl mx-auto flex justify-between items-center text-gray-800">
            <Link
              className="flex items-center space-x-3"
              to="/planes-de-lectura"
              replace
            >
              <Button
                variant="ghost"
                iconPosition="start"
                icon={
                  <ArrowLeft color="currentColor" strokeWidth={2} size={16} />
                }
              >
                Planes de lectura
              </Button>
            </Link>
            <div className="flex items-center gap-3 font-semibold text-xs border border-gray-100 prounded px-3 py-2 rounded uppercase">
              <IcomoonReact
                iconSet={iconSet}
                color={tokens.colors[getColor(plan.categoriaId) + "500"]}
                size={16}
                icon={getIcon(plan.categoriaId)}
                className="w-auto"
              />
              {plan.categoria}
            </div>
          </div>
        </TopHeader>
        <div className="max-w-screen-xl mx-auto">
          <Header theme={plan.categoriaId}>
            <div className="text-gray-800">
              <h1 className="text-4xl font-bold">{plan.titulo}</h1>
              {plan.subtitulo && (
                <h5 className="text-xl font-bold my-5">{plan.subtitulo}</h5>
              )}
              <p className="leading-relaxed">{plan.descripcion}</p>
              <div className="flex items-center space-x-2 mt-5 mb-10">
                <a
                  href={
                    plan.tableOfContents[0]?.slug
                      ? `http://atril.clicksound.io:3000/r/plan-` +
                        plan.slug +
                        "/" +
                        plan.tableOfContents[0]?.slug
                      : "http://atril.clicksound.io:3000/login"
                  }
                  className="inline-block py-2 px-4 rounded text-white font-bold bg-primary-500 hover:bg-primary-600"
                >
                  Empezar plan
                </a>
              </div>
            </div>
          </Header>
          <h3 className="text-2xl mb-8 font-bold">Índice de contenidos</h3>
          {tableOfContents.length ? (
            tableOfContents.map(content => (
              <IndiceElement
                key={content.id}
                planslug={plan.slug}
                chapter={content}
                parent={parent}
              />
            ))
          ) : (
            <div>Sin contenido disponible...</div>
          )}
        </div>
      </Container>
      <ContentSection
        title="Planes relacionados"
        bgColor={tokens.colors.gray50}
      >
        <PlanList withSlider filter={plan.categoriaId} />
      </ContentSection>
      <CallToAction />
      <Footer />
    </>
  )
}

export const query = graphql`
  query Plan($id: ID!) {
    atril {
      plan(id: $id) {
        titulo
        slug
        subtitulo
        categoriaId
        descripcion
        categoria
        tableOfContents {
          id
          title
          depth
          slug
        }
      }
    }
  }
`
