export const colorThemes = [
  [
    '167, 196, 231',
    '221, 233, 247',
    '110, 137, 170'
  ],
  [
    '159, 213, 103',
    '221, 240, 221',
    '121, 168, 70'
  ],
  [
    '255, 193, 122',
    '255, 232, 204',
    '196, 145, 86'
  ],
  [
    '192, 167, 232',
    '203, 188, 229',
    '103, 56, 154'
  ],
  [
    '255, 163, 166',
    '241, 219, 219',
    '181, 99, 102'
  ],
]